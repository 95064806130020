var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "role-wrap" }, [
    _c(
      "div",
      { staticClass: "orders" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("SearchForm", {
              ref: "searchForm",
              staticStyle: { padding: "0" },
              attrs: {
                "form-list-extend": _vm.searchFormListPreOrder,
                "form-data": _vm.searchFormData,
                "default-form": _vm.defaultForm
              },
              on: {
                "update:formData": function(data) {
                  return (_vm.searchFormData = data)
                },
                search: _vm.reSearch
              }
            })
          ],
          1
        ),
        _c("FunctionHeader", {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: { "search-title": "请输入工单编号" },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.getBaseColumns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.preOrderStatusMaps[row.status]))
                ])
              }
            },
            {
              key: "hasProcessFlow",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.hasProcessFlowMaps[row.hasProcessFlow]))
                ])
              }
            },
            {
              key: "action",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _vm.permission("AddOrder")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", disabled: row.status == 1 },
                            on: {
                              click: function($event) {
                                return _vm.addOrder(row)
                              }
                            }
                          },
                          [_vm._v("补工单")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }