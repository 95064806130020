var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData,
              searchType: 1
            },
            on: {
              "update:formData": function($event) {
                _vm.searchFormData = $event
              },
              "update:form-data": function($event) {
                _vm.searchFormData = $event
              },
              search: _vm.searchTable
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            attrs: {
              "search-title": "请输入订单编号",
              tags: _vm.functionHeader.tags,
              "export-name": _vm.functionHeader.exportName,
              "export-params": _vm.functionHeader.exportParams,
              "import-name": _vm.functionHeader.importName
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "projectNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: _vm.getDetailPath(row, "view"),
                      text: row.projectNumber
                    }
                  })
                }
              },
              {
                key: "deliveryType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.projectDeliveryType[row.deliveryType].name)
                      )
                    ])
                  ])
                }
              },
              {
                key: "deliveryDate",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.deliveryDate,
                            placement: "top-end"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.deliveryDate) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "businessUnit",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.businessUnit != null
                            ? _vm.divisionOptions[row.businessUnit - 1].name
                            : ""
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "principalName",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.principalName,
                            placement: "top-end"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.principalName) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "stationNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.stationNumber,
                            placement: "top-end"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.stationNumber) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "executiveUnit",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.executiveUnit != null
                            ? _vm.executionDepartment[row.executiveUnit].name
                            : ""
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "projectApprovalNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [_vm._v(_vm._s(row.projectApprovalNumber))])
                  ])
                }
              },
              {
                key: "projectStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.projectStatus[row.projectStatus].name))
                    ])
                  ])
                }
              },
              {
                key: "auditStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.checkStatus[row.auditStatus].name))
                    ])
                  ])
                }
              },
              {
                key: "issuedStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.issuedStatus[row.issuedStatus].name))
                    ])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("IssueWorkOrder")
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                disabled: !row.stationNumber
                              },
                              on: {
                                click: function($event) {
                                  return _vm.issueWorkOrder(row)
                                }
                              }
                            },
                            [_vm._v("下达工单")]
                          )
                        : _vm._e(),
                      _vm.permission("IssueWorkOrder")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("SetStation")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.setStation(row)
                                }
                              }
                            },
                            [_vm._v("站号与交期")]
                          )
                        : _vm._e(),
                      _vm.permission("SetStation")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _c(
                        "el-dropdown",
                        {
                          on: {
                            command: function(command) {
                              _vm.more(row, command)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v(" 更多"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "td-dropdown-menu",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown"
                            },
                            [
                              _vm.permission("Modify")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "distribute" } },
                                    [_vm._v("分配")]
                                  )
                                : _vm._e(),
                              _vm.permission("Over")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "onOver" } },
                                    [_vm._v("结案")]
                                  )
                                : _vm._e(),
                              _vm.permission("Restart")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "restart" } },
                                    [_vm._v("重启")]
                                  )
                                : _vm._e(),
                              _vm.permission("Copy")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "copy" } },
                                    [_vm._v("复制")]
                                  )
                                : _vm._e(),
                              _vm.permission("Del")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "del" } },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.titles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c(
                "div",
                { attrs: { slot: "deliveryDate" }, slot: "deliveryDate" },
                [
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showTwoTime,
                        expression: "!showTwoTime"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      placeholder: "请选择交付日期",
                      disabled: _vm.isDisabled,
                      clearable: ""
                    },
                    on: { change: _vm.chooseStartTime },
                    model: {
                      value: _vm.deliveryOneDate,
                      callback: function($$v) {
                        _vm.deliveryOneDate = $$v
                      },
                      expression: "deliveryOneDate"
                    }
                  }),
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTwoTime,
                        expression: "showTwoTime"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      clearable: "",
                      disabled: _vm.isDisabled
                    },
                    on: { change: _vm.chooseStartTime },
                    model: {
                      value: _vm.deliveryTwoDate,
                      callback: function($$v) {
                        _vm.deliveryTwoDate = $$v
                      },
                      expression: "deliveryTwoDate"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "设置站号" },
          on: { onOk: _vm.submitStationForm },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        _vm._l(_vm.stationList, function(item) {
          return _c("div", { key: item.id }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-bottom": "10px" } },
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: item.isDisabled, placeholder: "站号" },
                  model: {
                    value: item.stationNumber,
                    callback: function($$v) {
                      _vm.$set(item, "stationNumber", $$v)
                    },
                    expression: "item.stationNumber"
                  }
                }),
                _c("el-date-picker", {
                  staticStyle: { width: "100%", "margin-left": "10px" },
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    placeholder: "请选择交期",
                    clearable: ""
                  },
                  model: {
                    value: item.deliveryDate,
                    callback: function($$v) {
                      _vm.$set(item, "deliveryDate", $$v)
                    },
                    expression: "item.deliveryDate"
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }