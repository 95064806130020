<template>
  <div class="role-wrap">
    <div class="orders">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="upDownWorkRecordFormList"
          :form-data="searchFormData"
          style="padding: 0"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        >
          <SelectRemoteSearch
            slot="procedureIds"
            ref="procedureIds"
            class="select"
            placeholder="工序名称/编码"
            multiple
            option-val="id"
            option-label="fullName"
            :api="api.procedureList"
            collapse-tags
            @change="val => {
              handleSelect('procedureIds', val)
            }
            "
          />
        </SearchForm>

      </div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :search-title="mode ? '请输入生产工单号/订单编号名称' : '请输入生产工单号/项目编号名称'"
        style="padding: 10px 0 10px 0;"
        :tags="!selectData.length ? functionHeader.tags :[]"
        class="func-header"
        @search="$refs.mTable.setTableData(true)"
      >
        <!--<div slot="all" v-show="selectData.length">-->
        <!--<el-button v-if="permission('Export')" size="small" type="primary"  @click="batchExport(false)">批量导出</el-button>-->
        <!--<span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">-->
          <!--已选-->
          <!--<span style="color:#607FFF">{{ getSelectionDataLength }}</span>-->
          <!--/ {{ total }}项-->
        <!--</span>-->
          <!--<el-button type="text" size="small" @click="resetSelectData">清空</el-button>-->
        <!--</div>-->
      </FunctionHeader>

      <MTable
        ref="mTable"
        class="table-left"
        :columns="getColumns"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
        :columns-key="columnsKey"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <div slot="direction" slot-scope="{ row }">
          {{ upDownWorkStatusMaps[row.direction]||'-' }}
        </div>
        <div slot="workHoursType" slot-scope="{ row }">
          {{ workHoursTypeMaps[row.workHoursType]||'-' }}
        </div>
        <el-date-picker
          v-if="row.isEdit"
          slot="upWorkTime"
          v-model="row.upWorkTime"
          slot-scope="{ row }"
          type="datetime"
          default-time="00:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        />
        <div v-else> {{ row.upWorkTime }}</div>
        <el-date-picker
          v-if="row.isEdit"
          slot="downWorkTime"
          v-model="row.downWorkTime"
          slot-scope="{ row }"
          type="datetime"
          default-time="00:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        />
        <div v-else> {{ row.downWorkTime }}</div>
        <el-input v-if="row.isEdit" slot="singleUpWorkWorkHours" v-model="row.singleUpWorkWorkHours" slot-scope="{ row }" placeholder="" />
        <div v-else> {{ row.singleUpWorkWorkHours }}</div>
        <div slot="action" slot-scope="{ row }">
          <template v-if="permission('Edit') && row.direction === 1">
            <el-button
              type="text"
              @click="onHandle(row.isEdit?'save':'edit',row)"
            >{{ row.isEdit?'保存':'编辑' }}</el-button>
          </template>
          <el-button
            v-if="row.isEdit"
            type="text"
            @click="row.isEdit=false"
          >取消</el-button>
          <template v-if="permission('Del')  && row.direction === 1">
            <el-button type="text" @click="onHandle('del',row)">删除</el-button>
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>
<script>
import api from '@/api/production/project'
import SelectRemoteSearch from '@/components/SelectRemoteSearch/index.vue'
import taskApi from '@/api/production/upDownWork'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { upDownWorkColumn, upDownWorkStatusMaps, workHoursTypeMaps } from './data'
import { upDownWorkRecordFormList } from './form-list'
import dayjs from 'dayjs'

export default {
  name: 'upDownWorkRecord',
  components: { FunctionHeader, SearchForm, SelectRemoteSearch },
  props:{
      projectId:{
        type: Number,
         default: undefined
      }
    },
  data() {
    return {
      api,
      params: {
        radio: 'upDownWorkRecord'
      },
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        exportParams: {},
        tags: []
      },
      upDownWorkStatusMaps,
      workHoursTypeMaps,
      upDownWorkRecordFormList,
      upDownWorkColumn,
      selectData: [],
      userOptions: [],
      transferSelectIds: [],
      assignVisibleNo: false,
      showAssign: false,
      total:0
    }
  },
  computed: {
    height() {
      return this.projectId ? this.$store.state.app.contentHeight - 220 - 65 - 42 : this.$store.state.app.contentHeight - 220 - 65
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    mode() {
      return this.$store.state.app.mode
    },
    getColumns(){
        if (this.mode) {
        return this.upDownWorkColumn.map(item => {
      const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
      return { ...item, label: newLabel }
    })
      } else {
        return this.upDownWorkColumn
      }
    },
  },
  created() {
    this.getUsers()
    this.getWorkHoursReasonList();
  },

  methods: {
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    onHandle(type, row) {
      if (type === 'edit') return this.$set(row, 'isEdit', true)
      const { id: id, upWorkTime, downWorkTime, singleUpWorkWorkHours } = row
      this.$confirm(`您确定要${type === 'save' ? '保存' : '删除'}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        switch (type) {
          case 'save': {
            const params = { id, upWorkTime, downWorkTime, singleUpWorkWorkHours }
            const res = await taskApi.updateUpDownWorkRecord(params)
            if (res) {
              this.$message.success('保存成功')
              this.$refs.mTable.setTableData(true)
            }
            break
          }
          case 'del':
            this.onDel(id)
            break

          default:
            break
        }
      })
    },
    async onDel(id) {
      const res = await taskApi.delUpDownWorkRecord(id)
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData(true)
      }
    },
    allMore(command) {
      const functions = {
        batchExport: () => {
          this.batchExport(false)
        }
      }
      functions[command]()
    },
    async batchExport(isAll) {
      const params = isAll ? this.functionHeader.exportParams : { ids: this.selectData.map(item => item.id) }
      const res = await taskApi.exportWorkingHoursList(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `上下工记录-${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleSelect(key, val) {
      this.searchFormData[key] = val
      this.$refs.mTable.setTableData(true)
    },

    async getUsers() {
      const res = await taskApi.getUsers()
      if (res) {
        this.upDownWorkRecordFormList[1].children[0].options = res.userList || []
      }
    },
    async getWorkHoursReasonList() {
      const res = await taskApi.getWorkHoursReasonList()
      if (res) {
        this.upDownWorkRecordFormList[6].children[0].options = res
      }
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
        this.$refs.procedureIds.value = []
      }
      this.$refs.mTable.setTableData(true)
    },

    // 列表
    async getTableData(condition, callback) {
      console.log(this.searchFormData, '--')
      const { page } = condition
      const { planStartTime, planEndTime, ...data } = this.searchFormData
      const params = {
        ...data,
        search: this.functionHeader.searchVal,
        upWorkStartTime: planStartTime ? planStartTime[0] : null,
        upWorkEndTime: planStartTime ? planStartTime[1] : null,
        downWorkStartTime: planEndTime ? planEndTime[0] : null,
        downWorkEndTime: planEndTime ? planEndTime[1] : null
      }
      this.functionHeader.exportParams = params
      const res = await taskApi.getUpDownWorkRecordListPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  padding: 10px;
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
::v-deep .el-table {
  .cell {
    display: flex;
    align-items: center;
  }
}
</style>
