import { columns, projectStatusOptions } from './columns'
import { transformColumnsToForm } from '@/utils'
let functionList = {}
export const getPlanFunctionList = val => {
  functionList = val
}
export const formList = transformColumnsToForm(columns)


// 项目任务表单搜索字段
export const searchFormList = [
  {
    colSpan: 6,
    key: 'statusList',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '订单状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: projectStatusOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'source',
    tagName: 'el-select',
    props: {
      placeholder: '订单来源',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: [
          { name: 'MES新增', id: 0 },
          { name: 'excel导入', id: 1 },
          { name: '外部系统导入', id: 2 }
        ]
      }
    ]
  },
  {
    colSpan: 6,
    key: 'customerId',
    tagName: 'el-select',
    props: {
      placeholder: '客户'
    },
    directives: [
      { name: 'selectScrollEnd',
        value: () => {
          console.log('滚动触底了，可以请求下一页了')
        }
      }
    ],
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'businessUnits',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '业务部门'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'deliveryType',
    tagName: 'el-select',
    props: {
      placeholder: '交付类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          { name: '一次交付', id: 0 },
          { name: '分批交付', id: 1 }
        ],
        apiName: ''
      }
    ]
  },
  {
    colSpan: 6,
    key: 'dateRange',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '交付日期开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  // {
  //   colSpan: 6,
  //   key: 'deliveryEndTime',
  //   tagName: 'el-date-picker',
  //   props: {
  //     rangeSeparator: '至',
  //     startPlaceholder: '交付结束的开始时间',
  //     endPlaceholder: '结束时间',
  //     align: 'center',
  //     type: 'daterange',
  //     valueFormat: 'yyyy-MM-dd HH:mm:ss',
  //     format: 'yyyy-MM-dd HH:mm:ss',
  //     defaultTime: ['00:00:00', '23:59:59']
  //   }
  // },
  {
    colSpan: 6,
    key: 'principalId',
    tagName: 'el-select',
    props: {
      placeholder: '负责人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  }
]
