<template>
  <div class="home-wrap">
    <template>
      <div class="container-top common-pad">
        <div style="margin-bottom:10px;">

        <el-row :gutter="12">
          <el-col :span="6">
          <m-remote-select
             style="width:100%"
                v-model="projectId"
                class="input-prepend"
                :searchType="'projectStatistic'"
                :placeholder="'请选择工单'"
                :interface-fun="api.getWorkOrderListPage"
                :options.sync="projectList"
                filterable
                clearable
                multiple
                :label-name="'producePlanNumber'"
                :search-name="'producePlanNumber'"
                :value-name ="'producePlanId'"
                @on-change="projectChange"
              />
         </el-col>
         <el-col :span="8">
            <date-range
        :icon-show="false"
        style="width:100%"
        format-date-type="YYYY-MM-DD HH:mm:ss"
        :can-no-select="false"
        @dateRangeChange="(val)=> dateRangeChange(val)"
      />
    </el-col>
    <el-col :span="5">
          <el-select
        v-model="businessId"
        multiple
        style="width:100%"
                    placeholder="请选择业务部门"
                    filterable
                    @change="businessChange"
                  >
                    <el-option
                      v-for="item in businessList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  </el-col>
                  <el-col :span="5">
                  <el-select
                  style="width:100%"
                    v-model="userId"
                    multiple
                    placeholder="请选择用户"
                    filterable
                    @change="userChange"
                  >
                    <el-option
                      v-for="item in userList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-col>
                </el-row>
                </div>
                <div class="projectHours" style="display:flex;text-align: center;flex-wrap: wrap;justify-content: space-between;color: #303133;">
                  <div class="projectContent">
                    <div>
                    工单总工时（h）
                  </div>
                  <div class="hour">
                    {{ projectHour.workOrderTotalWorkHours }}
                  </div>
                  </div>
                  <div class="projectContent">
                    <div>
                    工单生产工时（h）
                  </div>
                  <div class="hour">
                    {{ projectHour.workOrderProductionWorkHours }}
                  </div>
                  </div>
                  <div class="projectContent">
                    <div>
                    工单停工工时（h）
                  </div>
                  <div class="hour">
                    {{ projectHour.workOrderStopWorkWorkHours }}
                  </div>
                  </div>
                  <div class="projectContent">
                    <div>
                    工单返工工时（h）
                  </div>
                  <div class="hour">
                    {{ projectHour.workOrderReworkWorkHours }}
                  </div>
                  </div>
                  <div class="projectContent">
                    <div>
                    工单其他工时（h）
                  </div>
                  <div class="hour">
                    {{ projectHour.workOrderOtherWorkHours }}
                  </div>
                  </div>
                </div>

      </div>

        <div v-for="(child, idx) in chartList" :key="child.id + 200" >
          <div :class="child.fullscreen ? 'app-left-full' : ''">
            <el-card :key="child.id" class="box-card">
              <div slot="header" class="box-card-header">
                <div>
                  <span>{{ child.name }}</span>
                  <el-tooltip v-if="child.tooltip" effect="dark" :content="child.tooltip" placement="top" style="padding-left: 5px">
                    <span class="el-icon-question" />
                  </el-tooltip>
                </div>
                <div
                  :style="{
                    position: 'relative',
                    padding: '12px 0'
                  }"
                >
                  <div class="header-ri" @click="toggleFullScreen(child, idx)">
                    <el-tooltip v-if="!child.fullscreen" class="item" effect="dark" content="放大" placement="top">
                      <img class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
                    </el-tooltip>
                    <el-tooltip v-else class="item" effect="dark" content="缩小" placement="top">
                      <img class="ri-img" src="@/assets/narrow-amplification/narrow.png">
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div>
            <div class="order-cen">
                    <el-row>
                      <el-col :lg="12" :xl="8" v-for="item in child.content" :key="item.id" style="">
                <div>
                    <div style="color:#303133">{{ item.name }}</div>
                    <charts :ref="'charts' + item.id" :tab-info="item" />
                    </div>
                  </el-col>
                </el-row>
                </div>
                <CustomTable :ref="'charts' + child.id" v-if="child.type === 'table'" :tab-info="child" :to-day="toDay" />
                <charts :ref="'charts' + child.id" v-if="child.type === 'charts'" :tab-info="child" />
              </div>
            </el-card>
          </div>
        </div>
    </template>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import api from '@/api/projectManagement/project-hour-statistics'
  import DateRange from '@/views/statistic-statement/statement-manage/components/custom-table/date-range.vue'
  import Charts from '@/views/statistic-statement/statement-manage/components/charts/charts'
  import CustomTable from '@/views/statistic-statement/statement-manage/components/custom-table/custom-table'
  import { getDateRange } from '@/views/statistic-statement/statement-manage/data'
  import 'echarts/lib/component/legendScroll'
  export default {
    name: 'ProjectHourStatistics',
    components: { DateRange,Charts,CustomTable},
    data() {
      return {
        api,
        pieList:[],
        chartData: {
          columns: [],
          rows: []
    },
    projectId:[],
    businessId:[],
    userId:[],
    pieChartSettings:{},
    projectHour:{},
    tabInfo:{},
    toDay:false,
    dateRange:[dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss')],
    chartList: [
    {
      id: 1,
      content:[
      //   {
      //     id: 2,
      //     name: '工时类型占比',
      //     type: 'charts',
      //     method: 'post',
      //     dataUrl: 'statistics/private/projectWorkHoursStatistics/workHoursStatistics',
      //     dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      //     chartDataKey: 'workHoursType',
      //     hiddenSelect:true,
      //     chartType: 'pie'
      // },
      // {
      //     id: 3,
      //     name: '项目任务节点类型占比',
      //     type: 'charts',
      //     method: 'post',
      //     dataUrl: 'statistics/private/projectWorkHoursStatistics/projectTaskNodeTypeWorkHoursRatio',
      //     dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      //     chartDataKey: 'projectTaskNodeTypeWorkHoursRatio',
      //     hiddenSelect:true,
      //     chartType: 'pie'
      // },
      // {
      //     id: 4,
      //     name: '项目其他工时类型占比',
      //     type: 'charts',
      //     method: 'post',
      //     dataUrl: 'statistics/private/projectWorkHoursStatistics/projectOtherWorkHoursRatio',
      //     dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      //     chartDataKey: 'projectOtherWorkHoursRatio',
      //     hiddenSelect:true,
      //     chartType: 'pie'
      // },
      {
          id: 5,
          name: '工单工时类型占比',
          type: 'charts',
          method: 'post',
          dataUrl: 'statistics/private/projectWorkHoursStatistics/workHoursStatistics',
          dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
          chartDataKey: 'workOrderWorkHoursTypeRatio',
          hiddenSelect:true,
          chartType: 'pie'
      },
      {
          id: 6,
          name: '工时原因累计工时占比',
          type: 'charts',
          method: 'post',
          dataUrl: 'statistics/private/projectWorkHoursStatistics/workHoursReasonWorkHoursRatio',
          dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
          chartDataKey: 'workHoursReasonWorkHoursRatio',
          hiddenSelect:true,
          chartType: 'pie'
      },
      {
          id: 7,
          name: '工单其他工时类型占比',
          type: 'charts',
          method: 'post',
          dataUrl: 'statistics/private/projectWorkHoursStatistics/workOrderOtherWorkHoursRatio',
          dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
          chartDataKey: 'workOrderOtherWorkHoursRatio',
          hiddenSelect:true,
          chartType: 'pie'
      }
    ]
    },
    {
      id: 8,
      name: '工单工时类型工时趋势',
      type: 'charts',
      method: 'post',
      dataUrl: 'statistics/private/projectWorkHoursStatistics/workOrderWorkHoursTypeBarChart',
      dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      chartDataKey: 'workOrderWorkHoursType',
      hiddenSelect:true,
      chartType: 'bar'
    },
    {
      id: 9,
      name: '工时原因工时趋势',
      type: 'charts',
      method: 'post',
      dataUrl: 'statistics/private/projectWorkHoursStatistics/workHoursReasonBarChart',
      dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      chartDataKey: 'monthRatio',
      hiddenSelect:true,
      chartType: 'bar'
    },
    {
      id: 10,
      name: '人员工时统计',
      type: 'charts',
      method: 'post',
      dataUrl: 'statistics/private/projectWorkHoursStatistics/userWorkHoursStatisticsBarChart',
      dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      chartDataKey: 'personalWork',
      hiddenSelect:true,
      chartType: 'bar'
    },
    {
      id: 11,
      name: '人员工时明细',
      type: 'table',
      isExport:true,
      exportUrl:'statistics/private/projectWorkHoursStatistics/exportUserWorkHoursStatisticsList',
      dataUrl: 'statistics/private/projectWorkHoursStatistics/userWorkHoursStatisticsListPage',
      dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
      page: true,
      method: 'post',
      hiddenSelect:true,
      columns: [
        {
          prop: 'userJobNumber',
          label: '工号',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'userName',
          label: '姓名',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'departName',
          label: '组织',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'workHours',
          label: '工时/h',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'workHoursCategoryName',
          label: '工时类别',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'workHoursTypeName',
          label: '工时类型',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'workHoursReasonName',
          label: '工时原因',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'actualStartTime',
          label: '实际开始时间',
          sortable: 'custom',
          hiddenSearch: true
        },
        {
          prop: 'actualEndTime',
          label: '实际结束时间',
          sortable: 'custom',
          hiddenSearch: true
        },
        {
          prop: 'projectNumber',
          label: '项目编号',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'projectName',
          label: '项目名称',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'workOrderNumber',
          label: '工单编号',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'materialsName',
          label: '设备名称',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'procedureName',
          label: '工序名称',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'userGroupName',
          label: '用户组',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'userTypeName',
          label: '员工类型',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'remark',
          label: '备注',
          sortable: false,
          hiddenSearch: true
        }
      ]
    },
  ],
    projectList:[],
    businessList:[],
    userList:[]
      }
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
      this.getBusinessList()
      this.getUserList()
      this.getWorkHoursStatistics()
    },
    methods: {
      async getWorkHoursStatistics(){
        const res = await api.getWorkHoursStatistics({
               beginTime:this.dateRange[0],
               endTime:this.dateRange[1],
               departIds:this.businessId,
                workOrderIds:this.projectId,
                userIds:this.userId
      })
        if(res){
          this.projectHour = res
        }
      },
     async getBusinessList(){
      const res = await api.getBusinessList()
      if(res){
        this.businessList = res
      }
     },
     async getUserList(){
      const res = await api.getUserList({
        organizationIds:this.businessId.join(',')
      })
      if(res){
        this.userList = res
      }
     },
   // 全屏
  toggleFullScreen(child, idx) {
    this.chartList[idx].fullscreen = !child.fullscreen
    this.$set(this.chartList, idx, this.chartList[idx])
  },
  projectChange(){
          this.getWorkHoursStatistics()
          this.searchAllData()
      },
  dateRangeChange(val){
    this.dateRange = val
    this.getWorkHoursStatistics()
      this.searchAllData()
  },
  userChange(){
    this.getWorkHoursStatistics()
    this.searchAllData()
  },

  businessChange(){
    this.getWorkHoursStatistics()
    this.searchAllData()
    this.getUserList()
  },
    searchAllData(){
      this.chartList.forEach(item => {
          let expandTable = "charts" + item.id
          if(item.id != 1 && item.id != 11){
            this.$nextTick(() => {
            this.$refs[expandTable][0].getChartsData({
              beginTime:this.dateRange[0],
              endTime:this.dateRange[1],
              departIds:this.businessId,
              workOrderIds:this.projectId,
              userIds:this.userId
            });
          })
          }
          if(item.id === 11){
          item.dataCondition = {
              beginTime:this.dateRange[0],
              endTime:this.dateRange[1],
              departIds:this.businessId,
              workOrderIds:this.projectId,
              userIds:this.userId
          }
          this.$nextTick(() => {
            this.$refs[expandTable][0].$refs.mTable.setTableData()
          })
          }
          if(item.content){
            item.content.forEach(items => {
            let expandTables = "charts" + items.id
            this.$nextTick(() => {
            this.$refs[expandTables][0].getChartsData({
            beginTime:this.dateRange[0],
            endTime:this.dateRange[1],
            departIds:this.businessId,
            workOrderIds:this.projectId,
            userIds:this.userId
            });
          });
            })
          }
      })
    }
    }
  }
</script>

<style scoped lang="scss">
.box-card {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  &:not(:first-child) {
    margin-top: 15px;
  }
  ::v-deep .el-card__header {
    background: #f6f7f9;
    padding: 0 18px;
    .box-card-header {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  ::v-deep .el-card__body {
    padding: 20px 18px;
  }
}
.header-ri {
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  img {
    width: 15px;
    height: 15px;
    display: block;
  }
}
.app-left-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: #fff;
  width: 100%;
  z-index: 2000;
  padding: 20px;
}
  .title-count {
    height: 25px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #41456B;
    line-height: 25px;
    margin-bottom: 12px;
    width: 100%;
  }
  .home-containter {
    width: 256px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .img {
      width: 256px;
      height: 201px;
      display: block;
    }
    .home-font {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      line-height: 20px;
      margin-top: 20px;
      display: inline-block;
    }
  }

  ::v-deep .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 15px;
  }
  ::v-deep .m-radio-groups .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 8px;
  }
  ::v-deep {
    .m-radio-group .el-radio-button__inner {
      min-width: 70px;
    }
    .m-radio-group .el-radio-button {
      min-width: 80px;
    }
    .m-radio-groups .el-radio-button__inner {
      min-width: 50px;
      padding: 12px 10px;
    }
    .m-radio-groups .el-radio-button {
      min-width: 60px;
    }
    .app-main {
      padding-top: 0 !important;
    }
  }
  .all-lines {
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    // align-items: center;
    .checkAll {
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .tag {
      height: 22px;
      padding: 0px 10px;
      border-radius: 4px;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 22px;
      border-radius: 11px;
      border: 1px solid #607FFF;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        .el-button:first-child,
        .el-button:last-child {
          display: block;
        }
      }

      .el-button:first-child {
        color: #9597AE;
        padding: 0 3px;
        display: none;
        position: relative;
        right: 5px;
      }

      .el-button:last-child {
        color: #000000;
        padding: 0 3px;
        display: none;
        position: relative;
        left: 5px;
      }
    }

  }
  .all-title {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #41456B;
    line-height: 25px;
    margin-bottom: 12px;
    width: 100%;
  }
  .common-tit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tit {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4B4F77;
      line-height: 32px;
    }
    .m-radio-group {
      height: 32px;
    }
    .m-radio-groupss {
      height: 32px;
      width: 280px;
    }
  }
  .m-radio-groups {
    height: 32px;
    width: 250px;
  }
  .container-top {
    // height: 290px;
    background: #FFFFFF;
    border-radius: 4px;
    // display: flex;
    .projectContent{
      padding: 10px;
      font-size: 20px;
      position: relative;
      height: 80px;
      background: #e4efff;
      flex: 0 0 19%;
      border-radius: 8px;
      margin-bottom: 10px;
  .hour{
    position: absolute;
    left: 0px;
    bottom: 10px;
    width: 100%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #607fff;
  }
}
    .order-left {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-right: 30px;
      .left-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        height: 32px;
        .title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
        }
        .des {
          padding: 0 20px;
          background: rgba(0, 171, 41, .1);
          border-radius: 4px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #00BC35;
        }
      }
      .left-cen {
        border-radius: 4px;
        .cen-li {
          height: 42px;
          display: flex;
          justify-content: space-between;
          background: #ECEEF2;
          align-items: center;
          border-bottom: 1px solid #DDE3EB;
          &:first-child {
            border-radius: 4px 4px 0 0;
            .tip {
              border-radius: 4px 0 0 0;
            }
          }
          &:last-child {
            border-bottom: none;
            border-radius: 0 0 4px 4px;
            .tip {
              border-radius: 0 0 0 4px;
            }
          }
          .tip {
            width: 7px;
            height: 42px;
            background: #F7B500;
            margin-right: 16px;
          }
          .tit {
            flex: 2;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4B4F77;
          }
          .desc {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #F7B500;
            padding-right: 20px;
          }
        }
      }
      .left-bot {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 22px;
        .tip {
          color: #F7B500;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #F7B500;
            width: 100%;
          }
        }
        .tit {
          color: #607FFF;
          font-size: 10px;
          // word-break: break-all;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #607FFF;
            width: 100%;
          }
        }
        .desc {
          color: #32C5FF;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #32C5FF;
            width: 100%;
          }
        }
      }
    }
    .order-cen {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-left: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .order-right {
      flex: 1;
      margin-left: 20px;
      .right-tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 25px;
        margin-bottom: 12px;
      }
      .right-bot {
        height: 34px;
        background: #ECEEF2;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 5px;
        &:nth-child(even) {
          background: #EBEEFA;
        }
        .tip {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #545982;
        }
        .des {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393D60;
        }
      }
    }
  }
  .container-bottom {
    height: 425px;
    display: flex;
    margin: 20px 0;
    padding: 0;
    .product-right {
      flex: 1;
      width: 48%;
      padding: 10px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .product-left {
      flex: 1;
      width: 48%;
      padding: 10px;
      margin-right: 20px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-center {
    margin: 20px 0;
    display: flex;
    padding: 0;
    .work-left {
      background: #fff;
      flex: 1;
      width: 32%;
      padding: 10px;
      .left-bot {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 15px;
        word-break: break-all;
        .bot-li {
          width: 30.5%;
          height: 93px;
          background: #F6F7F9;
          border-radius: 4px;
          border: 1px solid #DDE3EB;
          padding-right: 10px;
          margin: 5px;
          position: relative;
          .tip {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 32px;
            height: 32px;
            display: block;
          }
          // &:first-child {
          //   margin-left: 0;
          // }
          // &:last-child {
          //   margin-right: 0;
          // }
          .tit {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            text-align: right;
            padding-top: 16px;
            line-height: 20px;
          }
          .count {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #41456B;
            text-align: right;
            margin-top: 5px;
            line-height: 22px;
          }
        }
      }
    }
    .work-center {
      flex: 1;
      width: 32%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .work-right {
      flex: 1;
      width: 32%;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-footer {
    display: flex;
    min-height: 283px;
    padding: 0;
    .inspection-left {
      width: 32%;
      background: #fff;
      padding: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .inspection-center {
      width: 32.4%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
    }
    .inspection-right {
      width: 32%;
      background: #fff;
      padding: 10px;
    }
    .left-bot {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-top: 25px;
      .bot-li {
        flex: 1;
        height: 174px;
        background: #F6F7F9;
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        padding-right: 10px;
        margin: 5px;
        position: relative;
        .tip {
          position: absolute;
          top: 7px;
          left: 7px;
          width: 32px;
          height: 32px;
          display: block;
        }
        // &:first-child {
        //   margin-left: 0;
        // }
        // &:last-child {
        //   margin-right: 0;
        // }
        .tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393D60;
          text-align: right;
          padding-top: 60px;
          line-height: 20px;
        }
        .count {
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
          text-align: right;
          line-height: 33px;
        }
      }
    }
  }
</style>
